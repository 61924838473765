import { useState } from "react";
import { Layout } from "../../components/layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Icon } from "../../components/icon";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { deleteProduct, fetchInventory } from "../../redux/inventory";
import { ImageHandler } from "./image-handler";

function updateProduct(values, images) {
  const imgMap = {};
  images.forEach((img, i) => {
    if (typeof img === "string") {
      imgMap[i] = img;
    } else {
      imgMap[i] = img.name;
    }
  });

  const formData = new FormData();

  Array.from(images).forEach((file, i) => {
    if (typeof file !== "string") {
      formData.append(`file-${i}`, file);
    }
  });

  for (const key in values) {
    formData.append(key, values[key]);
  }

  formData.append("map", JSON.stringify(imgMap));

  axios
    .post(
      "https://us-central1-kameleon-b7631.cloudfunctions.net/updateProduct",
      // "http://localhost:8080/kameleon-b7631/us-central1/updateProduct",
      formData
    )
    .then((res) => {
      console.log("res: ", res);
    });
}

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  shape: Yup.string().required("Required"),
  stock: Yup.number().required("Required"),
  price: Yup.number().required("Required"),
});

export function ExistingProductScreen() {
  const location = useLocation();

  const product = location.state.product;

  const [images, setImages] = useState(product.images);

  const navigate = useNavigate();

  const selectorToNav = `/products/#${product.shape.split(" ").join("-")}`;

  const formik = useFormik({
    initialValues: {
      name: product.name,
      shape: product.shape,
      stock: product.stock,
      price: product.price,
      thumbnail: product.thumbnail,
      id: product.id,
    },
    validationSchema,
    onSubmit: (values) => {
      updateProduct(values, images);
      dispatch(fetchInventory());
      navigate(selectorToNav);
    },
  });

  const dispatch = useDispatch();

  async function deleteExistingProduct(id) {
    console.log("id to delete", id);
    await dispatch(deleteProduct(id));
    await dispatch(fetchInventory());
    navigate(selectorToNav);
  }

  const { shapes } = useSelector((state) => state.shapes);

  return (
    <Layout>
      <form
        onSubmit={formik.handleSubmit}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Información del producto
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Este producto será público para los clientes tan pronto como se
                apriete el botón guardar
              </p>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Nombre
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex flex-col rounded-md ">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className="flex-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                    />
                    {formik.errors.name && formik.touched.name && (
                      <p className="mt-2 text-xs text-red-600">
                        {formik.errors.name}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <ImageHandler images={images} setImages={setImages} />

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Forma
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex flex-row  gap-2  justify-start mt-4 flex-wrap">
                    {shapes.map((shape) => {
                      return (
                        <ShapePill
                          shape={shape.name}
                          activeShape={formik.values.shape}
                          setShape={formik.setFieldValue}
                        />
                      );
                    })}
                  </div>
                </div>
                {formik.errors.shape && formik.touched.shape && (
                  <p className="mt-2 text-xs text-red-600">
                    {formik.errors.shape}
                  </p>
                )}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Stock
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="number"
                    name="stock"
                    id="stock"
                    onChange={formik.handleChange}
                    value={formik.values.stock}
                    autoComplete="given-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                {formik.errors.stock && formik.touched.stock && (
                  <p className="mt-2 text-xs text-red-600">
                    {formik.errors.stock}
                  </p>
                )}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Precio
                </label>
                <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                  <span className="absolute -left-4 top-1.5 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    autoComplete="given-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  {formik.errors.price && formik.touched.price && (
                    <p className="mt-2 text-xs text-red-600">
                      {formik.errors.price}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                navigate(selectorToNav);
              }}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancelar
            </button>
            <button
              type="button"
              onClick={() => {
                deleteExistingProduct(product.id);
              }}
              className="ml-3 bg-red-200 py-2 px-4 rounded-md text-sm font-medium text-red-700 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Eliminar
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}

const ShapePill = ({ shape, activeShape, setShape }) => {
  const isActive = shape === activeShape;
  const styles =
    "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium";

  const available =
    "bg-purple-100 text-purple-800  cursor-pointer hover:bg-purple-200";

  const active = "bg-purple-600 text-white";

  return (
    <span
      onClick={() => setShape("shape", shape)}
      className={`${styles} ${isActive ? active : available}`}
    >
      {shape}
    </span>
  );
};
