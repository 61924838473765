import { Icon } from "../../../components";

export const HighlightImage = ({ index, setImages, images, image }) => {
  function setMainImage(image) {
    if (typeof image === "string") {
      const newState = images.filter((img) => image !== img);
      setImages([image, ...newState]);
    } else {
      const newState = images.filter((img) => image.name !== img.name);
      setImages([image, ...newState]);
    }
  }

  if (index == 0) {
    return (
      <div className="bg-white w-8 h-8 flex items-center justify-center rounded-full bg-green-500 absolute inset-2 ">
        <Icon icon="star" className="w-6 h-6 text-white" />
      </div>
    );
  } else {
    return (
      <div
        onClick={() => setMainImage(image)}
        className="bg-white w-8 h-8 flex items-center justify-center rounded-full bg-white absolute inset-2 "
      >
        <Icon icon="star" className="w-6 h-6 text-green-500" />
      </div>
    );
  }
};
