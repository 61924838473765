import { Layout } from "../components/layout";

export const DashboardScreen = () => {
  return (
    <Layout title="Dashboard">
      <div>
        <h1>Resumen de movimientos</h1>
      </div>
    </Layout>
  );
};
