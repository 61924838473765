import { DeleteImage } from "./delete";
import { HighlightImage } from "./highlight";

export const ImageList = ({ image, images, index, setImages }) => {
  function defineUrl() {
    if (typeof image === "string") {
      return image;
    } else {
      return URL.createObjectURL(image);
    }
  }

  return (
    <div className="relative">
      <img className="h-40 w-40 object-cover rounded-xl" src={defineUrl()} />
      <HighlightImage
        image={image}
        index={index}
        setImages={setImages}
        images={images}
      />
      <DeleteImage setImages={setImages} images={images} image={image} />
    </div>
  );
};
