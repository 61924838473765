import { useState } from "react";
import { Layout } from "../components/layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Icon } from "../components/icon";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { fetchInventory } from "../redux/inventory";

function createProduct(values, files) {
  console.log("values beofore: ", values);
  const formData = new FormData();

  Array.from(files).forEach((file, i) => {
    formData.append(`file-${i}`, file);
  });

  for (const key in values) {
    formData.append(key, values[key]);
  }

  console.log("form data values", Object.fromEntries(formData));

  axios
    .post(
      // "http://localhost:8080/kameleon-b7631/us-central1/newProduct",
      "https://us-central1-kameleon-b7631.cloudfunctions.net/newProduct",
      formData
    )
    .then((res) => {
      console.log("res: ", res);
    });
}

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  shape: Yup.string().required("Required"),
  stock: Yup.number().required("Required"),
  price: Yup.number().required("Required"),
});

export function NewProductScreen({}) {
  const [files, setFiles] = useState([]);

  const deleteFile = (name) => {
    const newFiles = files.filter((file) => file.name !== name);
    setFiles(newFiles);
  };

  const setMainImage = (name) => {
    const newMainImg = files.filter((file) => file.name === name);
    const newList = files.filter((file) => file.name !== name);
    const join = [...newMainImg, ...newList];

    setFiles(join);
  };

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: "",
      shape: location.state.state,
      stock: 1,
      price: 7500,
      category: "123",
    },
    // validationSchema,
    onSubmit: async (values) => {
      console.log("values liftof: ", values);
      await createProduct(values, files);
      await dispatch(fetchInventory());
      navigate(`/products/#${values.shape}`);
    },
  });

  const { shapes } = useSelector((state) => state.shapes);

  return (
    <Layout>
      <form
        onSubmit={formik.handleSubmit}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Información del producto
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Este producto será público para los clientes tan pronto como se
                apriete el botón guardar
              </p>
            </div>

            <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Nombre
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex flex-col rounded-md ">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autoComplete="name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className="flex-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-md sm:text-sm border-gray-300"
                    />
                    {formik.errors.name && formik.touched.name && (
                      <p className="mt-2 text-xs text-red-600">
                        {formik.errors.name}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <FilesInput
                files={files}
                setFiles={setFiles}
                deleteFile={deleteFile}
                setMainImage={setMainImage}
              />

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Forma
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="flex flex-row  gap-2  justify-start mt-4 flex-wrap">
                    {shapes.map((shape) => {
                      return (
                        <ShapePill
                          shape={shape.name}
                          activeShape={formik.values.shape}
                          setShape={formik.setFieldValue}
                        />
                      );
                    })}
                  </div>
                </div>
                {formik.errors.shape && formik.touched.shape && (
                  <p className="mt-2 text-xs text-red-600">
                    {formik.errors.shape}
                  </p>
                )}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Stock
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="number"
                    name="stock"
                    id="stock"
                    onChange={formik.handleChange}
                    value={formik.values.stock}
                    autoComplete="given-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                {formik.errors.stock && formik.touched.stock && (
                  <p className="mt-2 text-xs text-red-600">
                    {formik.errors.stock}
                  </p>
                )}
              </div>

              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  price
                </label>
                <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                  <span className="absolute -left-4 top-1.5 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    name="price"
                    id="price"
                    onChange={formik.handleChange}
                    value={formik.values.price}
                    autoComplete="given-name"
                    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  />
                  {formik.errors.price && formik.touched.price && (
                    <p className="mt-2 text-xs text-red-600">
                      {formik.errors.price}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => {
                navigate("/products");
              }}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Guardar
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
}

const FilesInput = ({ files, setFiles, deleteFile, setMainImage }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Imágenes
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2 flex gap-2 flex-wrap">
        <div className="grid grid-cols-4 gap-2">
          {files.map((img, i) => {
            return (
              <div className="relative">
                <img
                  className="h-40 w-40 object-cover rounded-xl"
                  src={URL.createObjectURL(img)}
                />
                {i == 0 ? (
                  <div className="bg-white w-8 h-8 flex items-center justify-center rounded-full bg-green-500 absolute inset-2 ">
                    <Icon icon="star" className="w-6 h-6 text-white" />
                  </div>
                ) : (
                  <div
                    onClick={() => setMainImage(img.name)}
                    className="bg-white w-8 h-8 flex items-center justify-center rounded-full bg-white absolute inset-2 "
                  >
                    <Icon icon="star" className="w-6 h-6 text-green-500" />
                  </div>
                )}
                <div
                  onClick={() => deleteFile(img.name)}
                  className="bg-white w-8 h-8 flex items-center justify-center rounded-full bg-red-500 absolute top-2 right-2 "
                >
                  <Icon icon="xmark" className="w-6 h-6 text-white" />
                </div>
              </div>
            );
          })}
          <div className="h-40 w-40 flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="space-y-1 text-center">
              <Icon icon="image" className="w-6 h-6" />
              <div className="flex justify-center text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 px-2"
                >
                  <p className="text-center">Sube una foto</p>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    multiple
                    onChange={(event) => {
                      const images = Array.from(event.target.files);
                      console.log("files: ", files);
                      console.log("images: ", images);
                      const join = [...files, ...images];
                      console.log("join: ", join);
                      //set files to state
                      setFiles(join);
                    }}
                  />
                </label>
              </div>
              <p className="text-xs text-gray-500">PNG o JPG hasta 3MB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ShapePill = ({ shape, activeShape, setShape }) => {
  const isActive = shape === activeShape;
  const styles =
    "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium";

  const available =
    "bg-purple-100 text-purple-800  cursor-pointer hover:bg-purple-200";

  const active = "bg-purple-600 text-white";

  return (
    <span
      onClick={() => setShape("shape", shape)}
      className={`${styles} ${isActive ? active : available}`}
    >
      {shape}
    </span>
  );
};
