export const UpdateStatus = () => {
  return (
    <div className="bg-gray-100 p-3 rounded-md flex flex-col items-end mt-2">
      <p className="text-sm font-medium text-left w-full text-gray-700 mb-1">
        Ingresa el tracking number
      </p>
      <input
        type="text"
        name="tracking"
        id="tracking"
        className="shadow-sm px-2.5 py-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder="you@example.com"
      />
      <div className="btn-primary-md mt-2">Enviado</div>
    </div>
  );
};
