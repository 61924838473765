import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Totals = ({ totals }) => {
  const { subtotal, shipping, total } = totals;
  const valueStyle = "text-normal text-gray-900 text-md";
  return (
    <div className="text-sm text-gray-500 font-medium grid grid-cols-3 border-t border-t-1.5 pt-2 mt-2">
      <div>
        <p>Subotal</p>
        <p className={valueStyle}>${subtotal.toLocaleString("us")}</p>
      </div>
      <div>
        <p>Envío</p>
        <p className={valueStyle}>${shipping.toLocaleString("us")}</p>
      </div>
      <div>
        <p>Total</p>
        <p className={valueStyle}>${total.toLocaleString("us")}</p>
      </div>
    </div>
  );
};

const ProductList = ({ products }) => {
  return (
    <div className="border-t border-t-1.5 pt-2 flex flex-col gap-2">
      {products.map((product) => {
        return (
          <div className="flex flex-row gap-2 items-center">
            <img src={product.thumbnail} className="h-6 w-6 rounded-full" />
            <p className="text-sm font-medium">1 x {product.name}</p>
          </div>
        );
      })}
    </div>
  );
};

const CustomerData = ({ customer, address }) => {
  function capitalize(str) {
    const arr = str.split(" ");
    const newArr = arr.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return newArr.join(" ");
  }
  return (
    <div className="pb-2">
      <p className="font-semibold text-gray-900">
        {customer.name} {customer.lastname}
      </p>
      <p className="text-sm text-gray-500">
        {capitalize(address.street.streetName)} #{address.number.number}
      </p>
      <p className="text-sm text-gray-500">{address.region.name}</p>
    </div>
  );
};

const Status = ({ status }) => {
  const [state, setState] = useState({ bg: "", label: "" });
  useEffect(() => {
    switch (status) {
      case "pending":
        setState({ bg: "bg-yellow-500", label: "Pendiente" });
        break;
      case "approved":
        setState({ bg: "bg-emerald-500", label: "Aprobado" });
        break;
      case "rejected":
        setState({ bg: "bg-red-500", label: "Rechazado" });
        break;
    }
  }, []);
  return (
    <div>
      <p
        className={`absolute top-2 right-2 text-sm text-white py-1 px-1.5 rounded-md inline-block ${state.bg}`}
      >
        {state.label}
      </p>
    </div>
  );
};

export const OrderCard = ({ order }) => {
  return (
    <Link
      to="/order-detail"
      state={order}
      className="relative bg-white rounded-lg p-4 shadow-md shadow-gray-500/10 hover:shadow-gray-500/50 cursor-pointer"
    >
      <CustomerData customer={order.customer} address={order.address} />
      <ProductList products={order.products} />
      <Totals totals={order.totals} />
      <Status status={order.status.status} />
    </Link>
  );
};
