import { Layout } from "../../components";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrders } from "../../redux/orders";
import { OrderCard } from "./order-card";
import { SegmentControl } from "./segment-control";

export const OrdersScreen = () => {
  const { orders } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrders());
  }, []);
  return (
    <Layout title="Pedidos">
      <SegmentControl />
      <div className="grid grid-cols-4 gap-4 py-4">
        {orders.map((order) => {
          return <OrderCard order={order} />;
        })}
      </div>
    </Layout>
  );
};
