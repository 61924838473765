import { Icon } from "../../../components/icon";
import { useFormik } from "formik";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchShapeCategories } from "../../../redux/shape-category";
import { fetchInventory } from "../../../redux/inventory";
import * as Yup from "yup";
import { InlineInput } from "./inline-input";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, "Debe tener al menos 3 letras")
    .required("Requerido"),
  tall: Yup.number("Debe ser un número").required("Requerido"),
  wide: Yup.number("Debe ser un número").required("Requerido"),
  deep: Yup.number("Debe ser un número").required("Requerido"),
  hook: Yup.string().required("Requerido"),
  weight: Yup.number("Debe ser un número").required("Requerido"),
});

export const EditingExistingShape = ({ cancelCreation, shape }) => {
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: shape.name,
      tall: shape.tall,
      wide: shape.wide,
      deep: shape.deep,
      weight: shape.weight,
      hook: shape.hook,
      id: shape.id,
    },
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      await axios.post(
        "https://us-central1-kameleon-b7631.cloudfunctions.net/updateShape",
        values
      );

      dispatch(fetchShapeCategories());
      dispatch(fetchInventory());

      cancelCreation();
    },
  });
  return (
    <div className="bg-white justify-between items-center grid grid-cols-12 px-6 py-6 shadow-sm rounded-lg">
      <div className="flex gap-4 col-span-10">
        <div className="w-50">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Nombre de la categoría
          </label>
          <div className="mt-1">
            <input
              type="name"
              name="name"
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className="w-full border block px-3 py-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
              placeholder="Argollas Abiertas"
            />
            {formik.errors.name && formik.touched.name && (
              <p className="text-xs text-red-500 font-medium pl-1 pt-1">
                {formik.errors.name}
              </p>
            )}
          </div>
        </div>
        <InlineInput
          desc="cm"
          label="Alto"
          id="tall"
          placeholder={5}
          value={formik.values.tall}
          onChange={formik.handleChange}
          error={formik.errors.tall}
          touched={formik.touched.tall}
        />
        <InlineInput
          desc="cm"
          id="wide"
          label="Ancho"
          placeholder={3}
          value={formik.values.wide}
          onChange={formik.handleChange}
          error={formik.errors.wide}
          touched={formik.touched.wide}
        />
        <InlineInput
          desc="cm"
          label="Groso"
          id="deep"
          placeholder={5}
          value={formik.values.deep}
          onChange={formik.handleChange}
          error={formik.errors.deep}
          touched={formik.touched.deep}
        />
        <InlineInput
          desc="gr"
          id="weight"
          label="Peso"
          placeholder={7}
          value={formik.values.weight}
          onChange={formik.handleChange}
          error={formik.errors.weight}
          touched={formik.touched.weight}
        />
        <div>
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700"
          >
            Anclaje
          </label>
          <select
            id="hook"
            name="hook"
            className="mt-1 block w-32 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue="Gancho"
            value={formik.values.hook}
            onChange={formik.handleChange}
            error={formik.errors.hook}
            touched={formik.touched.hook}
          >
            <option>Poste</option>
            <option>Gancho</option>
          </select>
        </div>
      </div>

      <div className="flex gap-4 col-span-2 justify-end items-center">
        <div
          onClick={cancelCreation}
          className="w-10 h-10 cursor-pointer bg-red-200 text-red-500 rounded-full flex items-center justify-center text-gray-500 hover:bg-red-500 hover:text-white"
        >
          <Icon icon="xmark" />
        </div>
        <div
          onClick={formik.handleSubmit}
          className="w-10 h-10 cursor-pointer bg-blue-200 text-blue-500 rounded-full flex items-center justify-center text-gray-500 hover:bg-blue-500 hover:text-white"
        >
          <Icon icon="cloud-arrow-up" />
        </div>
      </div>
    </div>
  );
};
