import { useLocation } from "react-router-dom";
import { PaperClipIcon } from "@heroicons/react/solid";
import { ProductList } from "./product-list";

export function Detail() {
  const { state } = useLocation();

  const { customer, address, date, id, products, shipping, status, totals } =
    state;

  function capitalize(str) {
    const arr = str.split(" ");
    const newArr = arr.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return newArr.join(" ");
  }

  return (
    <div className="col-span-2">
      {console.log("location: ", state)}
      <div className="mt-5 border-t border-gray-200">
        <dl className="divide-y divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Nombre</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {customer.name} {customer.lastname}
              </span>
              <span className="ml-4 flex-shrink-0"></span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{customer.email}</span>
              <span className="ml-4 flex-shrink-0"></span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Teléfono</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{customer.phone}</span>
              <span className="ml-4 flex-shrink-0"></span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">
              Dirección de envío
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {capitalize(address.street.streetName)} #{address.number.number}
                , {capitalize(address.county.countyName)},{" "}
                {capitalize(address.region.name)}
              </span>
              <span className="ml-4 flex-shrink-0"></span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Productos</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ProductList products={products} />
              <span className="ml-4 flex-shrink-0"></span>
            </dd>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Envío</dt>
            <p className=" text-sm text-gray-900">
              {capitalize(shipping.serviceDescription)} ${shipping.serviceValue}
            </p>
          </div>
          <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
            <dt className="text-sm font-medium text-gray-500">Total</dt>
            <p className=" text-sm text-gray-900">
              ${totals.total.toLocaleString("us")}
            </p>
          </div>
        </dl>
      </div>
    </div>
  );
}
