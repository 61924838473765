import { Layout } from "../../components";
import { Detail } from "./detail";
import { ShippingStatus } from "./shipping-status";
import { useLocation } from "react-router-dom";
import { DateTime } from "luxon";

function formatDate(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
}

export const OrderDetailScreen = () => {
  const { state } = useLocation();
  return (
    <Layout title={`Detalle del pedido #${state.id.split("-")[0]}`}>
      <p className="mt-2">{formatDate(state.date)}</p>
      <div className="grid grid-cols-3 gap-8">
        <Detail />

        <ShippingStatus />
      </div>
    </Layout>
  );
};
