import { Tracking } from "./tracking";
import { UpdateStatus } from "./update-status";

export const ShippingStatus = () => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
      <p>Status del pedido</p>
      <UpdateStatus />
      <Tracking />
    </div>
  );
};
