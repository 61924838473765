export const InlineInput = ({
  desc,
  placeholder,
  label,
  value,
  onChange,
  id,
  error,
  touched,
}) => {
  return (
    <div className="mt-1 flex rounded-md items-center">
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        <div className="flex">
          <input
            type="text"
            name={id}
            id={id}
            className="flex-1 min-w-0 block w-14 px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
          <span className="h-10 inline-flex items-center justify-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {desc}
          </span>
        </div>
        {error && touched && (
          <p className="text-xs text-red-500 font-medium pl-1 pt-1">{error}</p>
        )}
      </div>
    </div>
  );
};
