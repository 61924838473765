import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const style = {
//   "--fa-primary-color": "#FF0087",
//   "--fa-secondary-color": "#FFC511",
//   "--fa-primary-opacity": 1,
//   "--fa-secondary-opacity": 1,
// };

export const Icon = ({ icon = "coffee", className = "w-4 h-4", rotation }) => {
  return <FontAwesomeIcon icon={["far", icon]} className={`${className}`} />;
};
