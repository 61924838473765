import { useState, useEffect } from "react";
import { Layout } from "../../components/layout";
import { Product } from "./product";
import { ExistingShape, NewShape } from "./shape-category";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteShapeCategory,
  fetchShapeCategories,
} from "../../redux/shape-category";

import { fetchInventory } from "../../redux/inventory";
import { useLocation } from "react-router-dom";
import { Loader } from "../../components";

const AddProductButton = ({ onClick }) => {
  return (
    <dib onClick={onClick} className="btn-primary-sm">
      Nueva forma
    </dib>
  );
};

export const ProductsScreen = () => {
  const dispatch = useDispatch();
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  useEffect(() => {
    dispatch(fetchShapeCategories());
    dispatch(fetchInventory());
  }, []);

  const [create, setStatus] = useState(false);

  const { shapes, loading: loadingShapes } = useSelector(
    (state) => state.shapes
  );
  const { inventory, loading: loadingInventory } = useSelector(
    (state) => state.inventory
  );

  function createNewShape() {
    setStatus(!create);
  }

  async function deleteExistingShape(id) {
    await dispatch(deleteShapeCategory(id));
    await dispatch(fetchShapeCategories());
  }

  function filterInventory(shape) {
    return inventory.filter((product) => product.shape === shape.name);
  }

  return (
    <Layout
      title="Inventario"
      button={<AddProductButton onClick={() => createNewShape()} />}
    >
      <div className="mt-4 flex flex-col gap-4">
        {loadingShapes ||
          (loadingInventory && (
            <div className="mt-12">
              <Loader />
            </div>
          ))}

        {create && <NewShape cancelCreation={() => setStatus(false)} />}
        {!loadingShapes &&
          !loadingInventory &&
          shapes.map((product) => {
            return (
              <ExistingShape
                deleteExistingShape={deleteExistingShape}
                shape={product}
                inventory={filterInventory(product)}
              />
            );
          })}
      </div>
    </Layout>
  );
};
