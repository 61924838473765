const Button = ({ label }) => {
  return (
    <div className="px-3 py-2 border border-1.5 rounded-md bg-white shadow-sm cursor-pointer text-gray-600 hover:bg-indigo-500 hover:text-white hover:shadow-indigo-500/50 hover:border-indigo-600">
      <p className="text-sm font-medium">{label}</p>
    </div>
  );
};

export const SegmentControl = () => {
  return (
    <div className="flex w-full justify-center gap-4">
      <Button label="Pendiente" />
      <Button label="Enviado" />
      <Button label="Entregado" />
    </div>
  );
};
