import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ProductsScreen,
  NewProductScreen,
  ExistingProductScreen,
  OrdersScreen,
  OrderDetailScreen,
} from "./screens";
import { far } from "@fortawesome/pro-regular-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(far);

function App() {
  return (
    <div className="App" className="h-full">
      <BrowserRouter>
        <Routes>
          <Route path="/existing-product" element={<ExistingProductScreen />} />
          <Route path="/new-product" element={<NewProductScreen />} />
          <Route path="/products" element={<ProductsScreen />} />
          <Route path="/orders" element={<OrdersScreen />} />
          <Route path="/order-detail" element={<OrderDetailScreen />} />
          <Route path="/" element={<ProductsScreen />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
