import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchOrders = createAsyncThunk("fetch/orders", async () => {
  const query = await axios.post(
    `${process.env.REACT_APP_PRODUCTION}fetchAllOrders`
  );
  const orders = query.data;
  return orders;
});

const initialState = {
  orders: [],
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    initAuth: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [fetchOrders.fulfilled]: (state, action) => {
      state.orders = action.payload;
    },
  },
});

export const { initAuth } = ordersSlice.actions;

export default ordersSlice.reducer;
