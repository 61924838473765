import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchInventory = createAsyncThunk("get/inventory", async () => {
  const inventory = await axios.post(
    `${process.env.REACT_APP_PRODUCTION}get-getInventory`
  );
  return inventory.data;
});

export const deleteProduct = createAsyncThunk(
  "delete/inventory",
  async (id) => {
    console.log("id received: ", id);
    await axios.post(`${process.env.REACT_APP_PRODUCTION}deleteProduct`, {
      id,
    });
  }
);

const initialState = {
  inventory: [],
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setInventory: (state, action) => {
      state.inventory = action.payload;
    },
  },
  extraReducers: {
    [fetchInventory.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchInventory.fulfilled]: (state, action) => {
      state.inventory = action.payload;
      state.loading = false;
    },
    [deleteProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInventory } = inventorySlice.actions;

export default inventorySlice.reducer;
