import { Icon } from "../../../components";

export const ImageUploader = ({ images, setImages }) => {
  return (
    <div className="h-40 w-40 flex justify-center items-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      <div className="space-y-1 text-center">
        <Icon icon="image" className="w-6 h-6" />
        <div className="flex justify-center text-sm text-gray-600">
          <label
            htmlFor="file-upload"
            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 px-2"
          >
            <p className="text-center">Sube una foto</p>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="sr-only"
              multiple
              onChange={(event) => {
                const newImages = Array.from(event.target.files);
                const join = [...images, ...newImages];
                setImages(join);
              }}
            />
          </label>
        </div>
        <p className="text-xs text-gray-500">PNG o JPG hasta 3MB</p>
      </div>
    </div>
  );
};
