import { configureStore } from "@reduxjs/toolkit";
import inventoryReducer from "./inventory";
import authReducer from "./auth";
import shapesReducer from "./shape-category";
import ordersReducer from "./orders";

export const store = configureStore({
  reducer: {
    inventory: inventoryReducer,
    auth: authReducer,
    shapes: shapesReducer,
    orders: ordersReducer,
  },
});
