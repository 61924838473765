export const ProductList = ({ products }) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      {products.map((product) => {
        return (
          <div className="flex gap-2 border border-1.5 p-2 rounded-md bg-white">
            <img
              className="h-20 w-20 rounded-md"
              src={product.thumbnail}
              alt={`product-${product.name}`}
            />

            <div className="">
              <p className="text-gray-900">{product.name}</p>
              <p className="text-gray-500 text-sm">{product.shape}</p>

              <p className="mt-2">${product.price}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
