import { Icon } from "../../../components";

export const DeleteImage = ({ images, deleteFile, image, setImages }) => {
  function deleteFile() {
    if (typeof image === "string") {
      const newState = images.filter((img) => image !== img);

      setImages(newState);
    } else {
      const newState = images.filter((img) => image.name !== img.name);
      setImages(newState);
    }
  }
  return (
    <div
      onClick={() => deleteFile()}
      className="bg-white w-8 h-8 flex items-center justify-center rounded-full bg-red-500 absolute top-2 right-2 "
    >
      <Icon icon="xmark" className="w-6 h-6 text-white" />
    </div>
  );
};
