import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchShapeCategories = createAsyncThunk("get/shapes", async () => {
  const shapes = await axios.post(
    `${process.env.REACT_APP_PRODUCTION}getShapes`
  );
  return shapes.data;
});

export const deleteShapeCategory = createAsyncThunk(
  "delete/shapes",
  async (id) => {
    await axios.post(`${process.env.REACT_APP_PRODUCTION}deleteShape`, { id });
  }
);

const initialState = {
  shapes: [],
  loading: [],
  newShapes: [],
};

export const shapeCategorySlice = createSlice({
  name: "shape",
  initialState,
  reducers: {
    addNewShape: (state, action) => {
      state.newShapes = [...state.newShapes, {}];
    },
    saveNewShape: (state, action) => {
      state.user = action.payload;
    },
    editShape: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: {
    [fetchShapeCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchShapeCategories.fulfilled]: (state, action) => {
      state.shapes = action.payload;
      state.loading = false;
    },
    [deleteShapeCategory.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteShapeCategory.fulfilled]: (state, action) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addNewShape,
  saveNewShape,
  editShape,
  deleteShape,
  getAllShapes,
} = shapeCategorySlice.actions;

export default shapeCategorySlice.reducer;
