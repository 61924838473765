import { Icon } from "../../../components/icon";
import { useState } from "react";
import { EditingExistingShape } from "./edit";
import { useNavigate, Link } from "react-router-dom";

export const ExistingShape = ({ shape, inventory, deleteExistingShape }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [open, toggleVisibility] = useState(true);
  const navigate = useNavigate();

  if (isEditing) {
    return (
      <EditingExistingShape
        cancelCreation={() => setIsEditing(false)}
        shape={shape}
      />
    );
  }

  return (
    <div
      className="bg-white shadow-sm rounded-lg"
      id={shape.name.replace(" ", "-")}
    >
      <div className="bg-white justify-between items-center grid grid-cols-12 px-6 py-4 rounded-lg">
        <div className="flex gap-10 col-span-8">
          <p className="text-lg font-semibold justify-center items-center flex">
            <span className="text-xs text-gray-500 mr-1">
              ({inventory.length})
            </span>{" "}
            {shape.name}
            <span
              className={`ml-3 transform transition-all ${
                open ? "rotate-0" : "rotate-90"
              }`}
              onClick={() => toggleVisibility(!open)}
            >
              <Icon icon="chevron-down" />
            </span>
          </p>
          <div className="flex gap-4">
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-yellow-800">
              <div
                style={{ transform: "rotate(135deg)" }}
                className=" mr-1 flex justify-center items-center"
              >
                <Icon
                  icon="arrow-up-right-and-arrow-down-left-from-center"
                  className="w-2.5 h-2.5"
                />
              </div>
              <span>{shape.tall}cm</span>
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800">
              <div
                style={{ transform: "rotate(45deg)" }}
                className=" mr-1 flex justify-center items-center"
              >
                <Icon
                  icon="arrow-up-right-and-arrow-down-left-from-center"
                  className="w-2.5 h-2.5"
                />
              </div>
              <span>{shape.wide}cm</span>
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800">
              <div className=" mr-1 flex justify-center items-center">
                <Icon icon="arrows-from-line" className="w-2.5 h-2.5" />
              </div>
              <span>{shape.deep}cm</span>
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-pink-100 text-pink-800">
              <div className=" mr-1 flex justify-center items-center">
                <Icon icon="weight-scale" className="w-2.5 h-2.5" />
              </div>
              <span>{shape.weight}gr</span>
            </span>
            <span className="inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-indigo-100 text-indigo-800">
              <div className=" mr-1 flex justify-center items-center">
                <Icon icon="clothes-hanger" className="w-2.5 h-2.5" />
              </div>
              <span>{shape.hook}</span>
            </span>
          </div>
        </div>
        <div className="flex gap-4 col-span-4 justify-end items-center">
          <div
            onClick={() => setIsEditing(!isEditing)}
            className="w-10 h-10 cursor-pointer bg-blue-200 text-blue-500 rounded-full flex items-center justify-center text-gray-500 hover:bg-blue-400 hover:text-white"
          >
            <Icon icon="pen" />
          </div>
          <div
            onClick={() => deleteExistingShape(shape.id)}
            className="w-10 h-10 cursor-pointer bg-red-200 text-red-500 rounded-full flex items-center justify-center text-gray-500 hover:bg-red-400 hover:text-white"
          >
            <Icon icon="trash" />
          </div>
          <Link
            to="/new-product"
            state={{ state: shape.name }}
            className="w-10 h-10 cursor-pointer bg-green-200 text-green-500 rounded-full flex items-center justify-center hover:bg-green-400 hover:text-white"
          >
            <Icon icon="plus" />
          </Link>
        </div>
      </div>
      {open && (
        <>
          {inventory.length > 0 && (
            <div className="grid grid-cols-8 pb-4 px-4 gap-2">
              {inventory.map((product) => {
                return <Product product={product} navigate={navigate} />;
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

const Product = ({ product, navigate }) => {
  return (
    <div>
      <img
        onClick={() => navigate("/existing-product", { state: { product } })}
        className="w-full h-auto rounded-md border cursor-pointer"
        src={product.images[0]}
      />
      <p className="text-sm font-medium text-gray-600 mt-2">{product.name}</p>
      <p className="text-xs text-gray-500">
        ${product.price.toLocaleString("us")}
      </p>
    </div>
  );
};
