import { useState } from "react";
import { Icon } from "../../../components/icon";
import { ImageList } from "./image-list";
import { ImageUploader } from "./uploader";

export const ImageHandler = ({ images, setImages }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        htmlFor="cover-photo"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        Imágenes
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2 flex gap-2 flex-wrap">
        <div className="grid grid-cols-4 gap-2">
          {images.map((img, i) => {
            return (
              <ImageList
                images={images}
                image={img}
                key={`img_${i}`}
                index={i}
                setImages={setImages}
              />
            );
          })}
          <ImageUploader images={images} setImages={setImages} />
        </div>
      </div>
    </div>
  );
};
